<template>
  <v-container class="container-card">
    <v-card>
      <Empresas />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Empresas from "@/components/comissao/realizados/empresas/Empresas";
export default {
  components: {
    Empresas
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Realizado Empresa");
  }
};
</script>

<style></style>
