<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="900" persistent>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="toolbar-color">
            <span class="headline ml-2"> Cadastro de Realizado - Empresa </span>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <!-- Segmento -->
                <v-col cols="12" sm="4">
                  <BaseSelect
                    prepend-inner-icon="mdi-file-table-box-multiple-outline"
                    clearable
                    single-line
                    label="Segmento"
                    item-text="descricao"
                    item-value="id_band"
                    service="sistemaService.tipoBandeiras"
                    :filters="{ id_band: '2,4' }"
                    v-model="form.id_band"
                    :isCompany="true"
                  />
                </v-col>
                <!-- Empresa -->
                <v-col cols="12" sm="4">
                  <BaseSelect
                    prepend-inner-icon="mdi-domain"
                    clearable
                    single-line
                    label="Empresas"
                    item-text="apelido"
                    item-value="id_empresa"
                    service="sistemaService.empresa"
                    v-model="form.id_empresa"
                    :isCompany="true"
                    :rules="[rules.required]"
                    :filters="{ id_band: form.id_band, calcula_comissao: 'S' }"
                  />
                </v-col>
                <!-- Indicadores -->
                <v-col cols="12" sm="4">
                  <BaseSelect
                    prepend-inner-icon="mdi-finance"
                    single-line
                    label="Indicadores"
                    item-text="indicador"
                    item-value="id_indicador"
                    clearable
                    v-model="form.id_indicador"
                    service="sistemaService.indicadores"
                    :rules="[rules.required]"
                    :filters="{ ativo: 'S' }"
                    :hasCode="true"
                  />
                </v-col>
                <!-- TipoComissao -->
                <v-col cols="12" sm="4">
                  <BaseSelect
                    prepend-inner-icon="mdi-finance"
                    single-line
                    label="Tipo de comissão"
                    item-text="tipo_comissao"
                    item-value="id_tipo_comissao"
                    clearable
                    chips
                    v-model="form.id_tipo_comissao"
                    service="comissaoService.tipoComissao"
                    :rules="[rules.required]"
                    :filters="{ ativo: 'S' }"
                  />
                </v-col>

                <!-- Tipo Evento -->
                <v-col cols="12" sm="8"
                  ><BaseSelect
                    prepend-inner-icon="mdi-calendar-text"
                    single-line
                    chips
                    label="Tipo Evento"
                    item-text="tipo_evento"
                    item-value="id_tipo_evento"
                    v-model="form.id_tipo_evento"
                    service="comissaoService.tipoEvento"
                    clearable
                    :rules="[rules.required]"
                    @change="fetchTipoFechamento()"
                    :filters="{ id_tipo_evento: 'C' }"
                  />
                </v-col>
                <!-- Tipo Dado -->
                <v-col cols="12" sm="6" md="4"
                  ><BaseSelect
                    prepend-inner-icon="mdi-calendar-end"
                    service="comissaoService.tipoDados"
                    chips
                    label="Tipo Dado"
                    item-text="descricao"
                    item-value="id_tipo_dado"
                    v-model="form.id_tipo_dado"
                    clearable
                    single-line
                    :rules="[rules.required]"
                    :filters="{ status: 'S' }"
                    @change="fetchTipoFechamento()"
                  />
                </v-col>
                <!-- Tipo Fechamento -->
                <v-col cols="12" sm="6" md="5"
                  ><v-autocomplete
                    :rules="[rules.required]"
                    :items="tipoFechamentoItems"
                    chips
                    label="Tipo Fechamento"
                    item-text="textTipoFechamento"
                    single-line
                    item-value="id_tipo_fechamento"
                    v-model="form.id_tipo_fechamento"
                    clearable
                    prepend-inner-icon="mdi-calendar-end"
                    :disabled="
                      form.id_tipo_evento == undefined ||
                        form.id_tipo_dado == undefined
                    "
                  ></v-autocomplete>
                </v-col>
                <!-- Tipo Lançamento -->
                <v-col cols="12" sm="6" md="3"
                  ><v-autocomplete
                    :rules="[rules.required]"
                    :items="tipoLancamentoItems"
                    label="Lançamento"
                    item-text="text"
                    item-value="value"
                    v-model="form.lanc"
                    clearable
                    chips
                    prepend-inner-icon="mdi-apple-keyboard-shift"
                  ></v-autocomplete>
                </v-col>
                <!-- Quantidade -->
                <v-col cols="12" sm="6" md="4"
                  ><v-text-field
                    :rules="[rules.required]"
                    label="Quantidade"
                    type="number"
                    v-model="form.qtde"
                    prepend-inner-icon="mdi-counter"
                  ></v-text-field>
                </v-col>
                <!-- Valor -->
                <v-col cols="12" sm="6" md="5"
                  ><vuetify-money
                    :rules="[rules.required]"
                    label="Valor"
                    valueWhenIsEmpty="0"
                    :options="optionsVuetifyMoney"
                    v-model="form.valor"
                    prepend-inner-icon="mdi-currency-usd"
                  ></vuetify-money>
                </v-col>
                <!-- Valor Desc -->
                <v-col cols="12" sm="6" md="3"
                  ><v-text-field
                    :rules="[rules.required]"
                    label="Valor de desconto"
                    prepend-inner-icon="mdi-currency-usd-off"
                    type="number"
                    v-model="form.valor_desc"
                  ></v-text-field>
                </v-col>

                <!-- Tipo de Pagamento -->
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="labelBtn != 'Cadastrar' ? editarColaborador() : store()"
            >{{ labelBtn }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BaseSelect from "@/components/shared/BaseSelectService";
import converteDate from "@/mixins/tipoFechamento";
import rules from "@/mixins/inputRules";
import comissao from "@/services/http/comissaoService";

export default {
  name: "ModalCadastroRealizadosEmpresas",

  components: {
    BaseSelect
  },

  mixins: [converteDate, rules],

  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    labelBtn: {
      type: String
    }
  },

  data() {
    return {
      form: {
        id_empresa: undefined,
        id_colaborador: "",
        id_tipo_fechamento: "",
        qtde: "",
        valor: "",
        lanc: "",
        valor_desc: "",
        id_indicador: ""
      },
      tipoFechamentoItems: [],
      tipoLancamentoItems: [
        {
          value: "A",
          text: "Automático"
        },
        {
          value: "M",
          text: "Manual"
        }
      ],
      optionsVuetifyMoney: {
        locale: "pt-BR",
        prefix: "R$",
        length: 11,
        precision: 2
      }
    };
  },
  async mounted() {
    await this.fetchTipoFechamento();
  },

  methods: {
    async fetchTipoFechamento() {
      let { data } = await comissao()
        .tipoFechamento()
        .show({
          per_page: -1,
          id_tipo_evento: this.form.id_tipo_evento,
          id_tipo_dado: this.form.id_tipo_dado
        });
      this.tipoFechamentoItems = data.data.map(item => {
        item["textTipoFechamento"] = `${this.converteDate(
          item.data_inicio
        )} - ${this.converteDate(item.data_final)}`;
        return item;
      });
    },

    async store() {
      if (!this.$refs.form.validate()) return;
      await comissao()
        .realizadoEmpresa()
        .store(this.form, { notification: true });
      this.close();
    },

    close() {
      this.$emit("closeModal");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
