<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id_empresa"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Empresas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom v-can-access="782" @click="openModal('Cadastrar')" />
          <FilterEmpresas
            @selectedFilters="fetchRealizadoEmpresas((filters = $event))"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>

      <template v-slot:[`item.apelido`]="{ item }">
        {{ `${item.apelido} - ${item.nome_empresa}` }}
      </template>

      <template v-slot:[`item.qtd_indicadores`]="{ item }">
        {{ item.dados.length }}
      </template>

      <template v-slot:[`item.mes_ref`]="{ item }">
        {{ `${item.mes_ref}/${item.ano_ref}` }}
      </template>

      <!-- expanded -->

      <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
        <v-btn small text v-if="!isExpanded" @click="expand(!isExpanded)">
          expandir
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn text small v-else @click="expand(!isExpanded)">
          ocultar
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-0" :colspan="headers.length">
          <ListaRealizadoEmpresa :items="item.dados" />
        </td>
      </template>
    </BaseTable>
    <ModalCadastro
      :dialog="dialog"
      :labelBtn="labelBtn"
      @closeModal="dialog = false"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable.vue";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import BaseSegmento from "@/components/shared/BaseSegmento.vue";
import ModalCadastro from "./ModalEmpresas.vue";
import FilterEmpresas from "./FilterEmpresas.vue";
import ListaRealizadoEmpresa from "./ListaRealizadoEmpresa.vue";
import comissao from "@/services/http/comissaoService";

export default {
  components: {
    BaseTable,
    RegisterBottom,
    BaseSegmento,
    ModalCadastro,
    FilterEmpresas,
    ListaRealizadoEmpresa
  },

  data() {
    return {
      loading: false,
      dialog: false,
      singleExpand: false,
      labelBtn: "",
      expanded: [],
      filters: "",
      headers: [
        { text: "Segmento", value: "id_band" },
        { text: "Empresa", value: "apelido" },
        { text: "QTD Indicadores", value: "qtd_indicadores", align: "center" },
        { text: "Mês Ref", value: "mes_ref", align: "center" }
      ],
      info: []
    };
  },

  methods: {
    openModal(label) {
      this.dialog = true;
      this.labelBtn = label;
    },
    async fetchRealizadoEmpresas(filters) {
      this.loading = true;
      let { data } = await comissao()
        .realizadoEmpresa()
        .show({ per_page: -1, ...filters });
      this.loading = false;
      const obj = this.groupByCompany(data.data, "id_empresa");
      this.info = Object.values(obj);
    },

    groupByCompany(array, key) {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || {
          id_empresa: currentValue.id_empresa,
          id_band: currentValue.id_band,
          apelido: currentValue.apelido,
          nome_empresa: currentValue.nome_empresa,
          mes_ref: currentValue.mes_ref,
          ano_ref: currentValue.ano_ref,
          dados: []
        }).dados.push(currentValue);
        return result;
      }, {});
    }
  },

  mounted() {
    if (this.filters != {}) {
      const date = new Date().toISOString().substr(0, 7);
      const [year, month] = date.split("-");
      const filter = {
        ano_ref: parseInt(year),
        mes_ref: parseInt(month)
      };
      this.fetchRealizadoEmpresas(filter);
    }
  }
};
</script>

<style></style>
